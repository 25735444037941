<template>
  <div>
    <b-row>
      <b-col>
        <b-card no-body>
          <b-card-header>
            <div>
              <b-card-title>รายละเอียดการเล่นเกม</b-card-title>
            </div>
          </b-card-header>
          <hr class="m-0">
          <b-card-body>
            <b-form-group
              label="วันที่"
              label-for="วันที่"
              label-cols="6"
              label-class="text-secondary"
            >
              <b-form-input
                id="วันที่"
                :value="gameHistoryDetail.date | datetime"
                class="text-right text-white"
                plaintext
              />
            </b-form-group>
            <b-form-group
              label="ชื่อเกม"
              label-for="ชื่อเกม"
              label-cols="6"
              label-class="text-secondary"
            >
              <b-form-input
                id="ชื่อเกม"
                :value="gameHistoryDetail.gameName"
                class="text-right text-white"
                plaintext
              />
            </b-form-group>
            <b-form-group
              label="Round ID"
              label-for="roundId"
              label-cols="6"
              label-class="text-secondary"
            >
              <b-form-input
                id="roundId"
                :value="gameHistoryDetail.roundId"
                class="text-right text-white"
                plaintext
              />
            </b-form-group>
            <b-form-group
              label="Username ของผู้เล่น"
              label-for="userName"
              label-cols="6"
              label-class="text-secondary"
            >
              <b-form-input
                id="userName"
                :value="gameHistoryDetail.userName"
                class="text-right text-white"
                plaintext
              />
            </b-form-group>
            <b-form-group
              label="ผลการเล่นเกม"
              label-for="type"
              label-cols="6"
              label-class="text-secondary"
            >
              <b-form-input
                id="type"
                :value="$displayHistoryType(gameHistoryDetail.type)"
                class="text-right text-weight-bolder"
                :class="$displayHistoryTextColor(gameHistoryDetail.type)"
                plaintext
              />
            </b-form-group>
            <b-form-group
              label="จำนวนเงินที่เดิมพัน"
              label-for="bet"
              label-cols="6"
              label-class="text-secondary"
            >
              <b-form-input
                id="bet"
                :value="gameHistoryDetail.bet | currency"
                class="text-right text-white"
                plaintext
              />
            </b-form-group>
            <b-form-group
              label="เงินรางวัลที่ได้รับ (หัก % แล้ว)"
              label-for="reward"
              label-cols="6"
              label-class="text-secondary"
            >
              <b-form-input
                id="reward"
                :value="gameHistoryDetail.reward |currency"
                class="text-right"
                plaintext
              />
            </b-form-group>
            <b-form-group
              label="ค่าธรรมเนียมที่โดนหัก"
              label-for="fee"
              label-cols="6"
              label-class="text-secondary"
            >
              <b-form-input
                id="fee"
                :value="gameHistoryDetail.fee |currency"
                class="text-right"
                plaintext
              />
            </b-form-group>
            <b-form-group
              label="ผู้เล่นคนอื่นในห้อง"
              label-for="players"
              label-cols="6"
              label-class="text-secondary"
            >
              <b-form-input
                id="players"
                :value="'ไม่มีชื่อ'"
                class="text-right text-white"
                plaintext
              />
            </b-form-group>
            <b-form-group
              label="ผู้เล่นชนะในห้อง"
              label-for="winner"
              label-cols="6"
              label-class="text-secondary"
            >
              <b-form-input
                id="winner"
                :value="'ไม่มีชื่อ'"
                class="text-right text-white"
                plaintext
              />
            </b-form-group>
            <b-form-group
              label="ชื่อตัวแทน"
              label-for="agentName"
              label-cols="6"
              label-class="text-secondary"
            >
              <b-form-input
                id="agentName"
                :value="gameHistoryDetail.agentName"
                class="text-right text-white"
                plaintext
              />
            </b-form-group>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'

export default {
  data() {
    return {}
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.request.isGettingRequestDetail,
    }),
    ...mapGetters(['gameHistoryDetail']),
    historyId() {
      const { params } = this.$route
      return params.id ? params.id : ''
    },
  },
  created() {
    this.fetchData()
  },
  methods: {
    ...mapActions(['getGameHistoryDetail']),
    fetchData() {
      this.getGameHistoryDetail(this.historyId)
    },
  },
}
</script>
